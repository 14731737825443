<script setup lang="ts">
  const { currentRoute } = useRouter();

  const { searchPanel, showModal, isClickedOnClinic } = storeToRefs(useSearchPanelStore());

  const openModal = () => {
    if (isClickedOnClinic.value) {
      isClickedOnClinic.value = false;
      showModal.value = false;
      return;
    }
    showModal.value = true;
  };

  watch(currentRoute, () => {
    showModal.value = false;
  });
</script>

<template>
  <div class="main-container">
    <div
      ref="searchPanel"
      class="inputs-wrapper"
      :style="{ zIndex: showModal ? 901: 99 }"
      @click="openModal"
    >
      <SearchPanelMainSearch />
      <SearchPanelClinicSearch />
      <Transition>
        <div v-show="showModal" class="modal" @click.stop="showModal = false"></div>
      </Transition>
      <div class="shadow"></div>
    </div>
    <div v-show="showModal" class="overlay" @click.stop="showModal = false"></div>
  </div>
</template>

<style scoped lang="scss">
.inputs-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 1;
  background-color: white;
  border-radius: 20px;
  outline: 1.5px solid #D5D7D9;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 0.5rem;
    background-color: transparent;
    box-shadow: none;
    outline: none;
  }
}
.modal {
  z-index: -1;
  position: absolute;
  background-color: white;
  height: 31.25rem;
  width: calc(100% + 2rem);
  top: -1rem;
  border-radius: 2.25rem;
  @media (max-width: 1023px) {
    display: none;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  background: rgba(0, 0, 0, 0.5);
  @media (max-width: 1023px) {
    display: none;
  }
}
.shadow {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: 1px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.10);
  @media (max-width: 1023px) {
    display: none;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
